import * as $ from 'jquery';
import Cookies from 'js-cookie';
import Bowser from 'bowser';

const SUMMARY_COOKIE_NAME = 'ml-session-summary';
const PAGEVIEWS_COOKIE_NAME = 'ml-session-pageviews';
const SUMMARY_OBJECT = {
    PAGE_VIEWS: 0,
    TIME_ENGAGED_IN_S: 0,
    OS_APPLE: 0,
    OS_WINDOWS: 0,
    DEVICE_MOBILE: 0,
};

/**
 * Class wrapper for Pageview tracking utility for ML use
 *
 * Tracking starts when instantiated and attaches a handler on the beforeunload event,
 * which calculates the time on page, stores the new pageview record in localStorage,
 * and summarizes all pageview records into a cookie for use by the ML Manager app
 */
class PageviewSummarizer {
    constructor() {
        this.pageviews = this.constructor.loadPageviewData();
        this.browser = Bowser.getParser(window.navigator.userAgent);
        let now = new Date();
        this.current = {
            page: document.location.pathname,
            loaded: now.getTime(),
        };

        this.onUnload = this.onUnload.bind(this);
        window.addEventListener('beforeunload', this.onUnload);
    }

    /**
     * Unload handler that calls the two tracking functions to update cookie and storage data
     */
    onUnload(){
        this.updatePageTracking();
        this.updateSummary();
    }

    /**
     * When called, this function calculates the elapsed time on page (in seconds)
     * and stuffs the pageview metric into the this.pageviews and writes the updated
     * list to the browser localStorage (for space concerns, max total cookie size is 4k
     * max localStorage is 2+MB).
     */
    updatePageTracking(){
        let now = new Date();
        this.current['elapsed'] = (now.getTime() - this.current['loaded'])/1000.0;

        this.pageviews.push(this.current);

        localStorage.setItem(PAGEVIEWS_COOKIE_NAME, JSON.stringify(this.pageviews));
    }

    /**
     * Reduce the pageview array to an updated summary of the
     */
    updateSummary(){
        this.summary = this.pageviews.reduce(this.constructor._summary_reducer, {...SUMMARY_OBJECT});

        if (this.browser.getOSName().toLowerCase() === 'macos' ||
            this.browser.getOSName().toLowerCase() === 'ios'
        ){
            this.summary.OS_APPLE = 1;
        }
        else if (this.browser.getOSName().toLowerCase() === 'windows'){
            this.summary.OS_WINDOWS = 1;
        }

        if (this.browser.getPlatformType().toLowerCase() === 'desktop')
            this.summary.DEVICE_MOBILE = 0;
        else
            this.summary.DEVICE_MOBILE = 1;

        Cookies.set(SUMMARY_COOKIE_NAME, JSON.stringify(this.summary), {expire: 365})
    }

    /**
     * Load the pageview data array from Local Storage or default to an empty Array
     *
     * @returns {Array}: Array of pageview objects
     */
    static loadPageviewData(){
        let pageview_data = localStorage.getItem(PAGEVIEWS_COOKIE_NAME);

        pageview_data = pageview_data != null ? JSON.parse(pageview_data) : [];

        return pageview_data;
    }

    /**
     * Reducer func for Pageview data to summarize pageview data
     *
     * @param acc: Accumulator
     * @param cur: Current Item
     * @returns {*}: Summary Object
     * @private
     */
    static _summary_reducer(acc, cur){
        acc.PAGE_VIEWS += 1;
        acc.TIME_ENGAGED_IN_S += cur.elapsed;

        return acc;
    }
}

export const pageTracker = new PageviewSummarizer();


